import React, { useState, useEffect, useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import { getToken } from '@services/checkout'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  background-color: white;
`

const Header = styled.span`
  font-size: 14px;
  color: #07263b;
  margin: 0px 0px 15px 0px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`

const InputWrapper = styled.div`
  margin: 0px 0px 15px 0px;
  border-bottom: 1px solid #07263b4d;
  flex: 1;
  height: 60px;
  iframe {
    height: 40px !important;
  }
`

const CreditCardSvg = styled(PaymentSvg)`
  padding: 0px 0px 15px 0px;
  height: 100%;
`

const ExpirationDateWrapper = styled.div`
  width: 45%;
  margin: 0px 0px 15px 0px;
`

const Label = styled.span`
  font-size: 0.6rem;
  color: #07263b;
`

const Dropdown = styled.select`
  border: none;
  border-radius: 0px;
  font-size: 18px;
  font-weight: 500;
  color: #07263b;
  height: 40px !important;
  border-bottom: 1px solid #07263b4d;
  display: block;
`

const CardNumberWrapper = styled.div`
  flex: 1;
`

/*
  This page exists SOLELY for the mobile app. The app loads this page u Webview in order to render the Cybersource Microform. 
  Unless you are intending to change the way the microform functions for the mobile app, 
  --DO NOT EDIT THIS PAGE--
*/

const renderMonthSelection = () => {
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const monthOptions = months.map(month => (
    <option value={month} key={month}>
      {month}
    </option>
  ))
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, 0)
  return (
    <Dropdown id="cardExpirationMonth" name="ccmonth" defaultValue={currentMonth}>
      {monthOptions}
    </Dropdown>
  )
}

const renderYearSelection = () => {
  const currentYear = new Date().getFullYear()
  const yearOptions = new Array(20).fill(null).map((_, index) => {
    const newYear = currentYear + index
    return (
      <option value={newYear} key={newYear}>
        {newYear}
      </option>
    )
  })
  return (
    <Dropdown id="cardExpirationYear" name="ccyear">
      {yearOptions}
    </Dropdown>
  )
}

const AppMicroformPage = ({ isScriptLoadSucceed }) => {
  const [cardType, setCardType] = useState('')

  useEffect(() => {
    if (!window.orderId) {
      if (window.ReactNativeWebView) {
        // Send user back to the app with an error
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            name: 'Error',
            description: 'There was an issue with your order. Please try again later.',
          }),
        )
      } else {
        // Send user to the homepage
        window.location.href = '/'
      }
    }
  }, [])

  useLayoutEffect(() => {
    const sendErrorToApp = (name = 'Error', description = 'Something went wrong. Please try again later.') =>
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ name, description }))

    const { orderId } = window

    const onSubmitCardEvent = (microformInstance, cardInfo) => {
      const expirationMonth = document.querySelector('#cardExpirationMonth').value
      const expirationYear = document.querySelector('#cardExpirationYear').value
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth() + 1

      const options = {
        expirationMonth,
        expirationYear,
      }

      const invalidExpDate =
        Number(options.expirationYear) === currentYear && Number(options.expirationMonth) < currentMonth

      if (invalidExpDate) {
        sendErrorToApp('Invalid Expiration Date', 'Please enter valid expiration date.')
      } else {
        // Create token and send either token or error back to the app
        microformInstance.createToken(options, async (error, response) => {
          if (error) {
            sendErrorToApp('Error', 'Please enter valid card.')
          } else {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                token: response,
                card: cardInfo,
              }),
            )
          }
        })
      }
    }

    const setupMicroform = async () => {
      const body = { orderId }
      let response
      try {
        response = await getToken(body)
      } catch (error) {
        sendErrorToApp()
      }

      const captureContext = response?.cybersourcev2Token
      if (!captureContext) {
        sendErrorToApp()
      } else {
        const styles = {
          input: {
            'font-size': '18px',
            'font-weight': '500',
            color: '#07263b',
          },
          '::placeholder': {
            color: '#07263bb3',
          },
          ':disabled': { cursor: 'not-allowed' },
          valid: { color: '#07263b' },
          invalid: { color: '#c00d1e' },
        }

        const flex = new window.Flex(captureContext)
        const microformInstance = flex?.microform({ styles })
        if (microformInstance?._microformId) {
          const number = microformInstance.createField('number', { placeholder: 'Enter card number' })
          const securityCode = microformInstance.createField('securityCode', { placeholder: '•••' })
          let cardInfo = {}

          number.on('change', data => {
            let creditCardType = data?.card[0]?.name
            const validCardTypes = ['amex', 'discover', 'mastercard', 'maestro', 'visa']
            if (validCardTypes.includes(creditCardType)) {
              if (creditCardType === 'maestro') creditCardType = 'discover'
              setCardType(creditCardType)
            } else {
              setCardType('')
            }
            if (data.valid) {
              cardInfo = {
                cardName: data.card[0].name,
                cardType: data.card[0].cybsCardType,
              }
            }
          })
          number.load('#cardNumber-container')
          securityCode.load('#securityCode-container')

          const submitCardVariable = document.querySelector('#submit-button-pressed')
          submitCardVariable.addEventListener('change', event => {
            onSubmitCardEvent(microformInstance, cardInfo)
          })
        } else {
          sendErrorToApp()
        }
      }
    }

    if (isScriptLoadSucceed) {
      setupMicroform()
    }
  }, [isScriptLoadSucceed])

  return (
    <Wrapper>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]}>
        <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet" />
        <style type="text/css">{`
          body {
            background-color: #fff;
            font-family: "Poppins", sans-serif
          }
        `}</style>
      </Helmet>
      <Header>Required Fields *</Header>
      <form action="/payment/complete" id="my-sample-form" method="post">
        <InputWrapper>
          <Label id="cardNumber-label">Card Number*</Label>
          <Row>
            <CardNumberWrapper id="cardNumber-container" />
            {!!cardType && <CreditCardSvg uniqueNameForId="creditMicroForm" cards={[cardType]} cordY="0" />}
          </Row>
        </InputWrapper>
        <Row>
          <ExpirationDateWrapper className="card-input">
            <Label htmlFor="cardExpirationMonth">Expiration Month*</Label>
            {renderMonthSelection()}
          </ExpirationDateWrapper>
          <ExpirationDateWrapper className="card-input">
            <Label htmlFor="cardExpirationYear">Expiration Year*</Label>
            {renderYearSelection()}
          </ExpirationDateWrapper>
        </Row>
        <InputWrapper className="card-input">
          <Label htmlFor="securityCode-container">CVV*</Label>
          <div id="securityCode-container" className="form-control" />
        </InputWrapper>
        <input id="submit-button-pressed" type="hidden" />
      </form>
    </Wrapper>
  )
}

export default scriptLoader([`https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js`])(
  AppMicroformPage,
)

AppMicroformPage.propTypes = {
  isScriptLoadSucceed: PropTypes.any,
}
